import {
  BooleanParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { IExecutor } from "../types/application";

export function useApplicationsQueryParams() {
  return useQueryParams({
    search: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
    per_page: withDefault(NumberParam, 100),
    date: withDefault(StringParam, null),
    status: withDefault(StringParam, ""),
    overdue: withDefault(BooleanParam, false),
    type: withDefault(StringParam, ""),
    official_number: withDefault(StringParam, ""),
    review_days_count: withDefault(NumberParam, 0),
    id: withDefault(NumberParam, null),
    current_executor: withDefault<
      Pick<IExecutor, "id" | "organization_name"> | null,
      IExecutor | null
    >(JsonParam, null),
  });
}
