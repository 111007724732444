import * as yup from "yup";
import { BREAKPOINT_DATES } from "../../utils/constants";
import { toast } from "react-toastify";

export const validationSchemaStep1 = yup.object().shape({
  organization_name: yup.string().required("Пожалуйста, заполните это поле"),
  email_address: yup
    .string()
    .email("Пожалуйста, введите корректный e-mail")
    .required("Пожалуйста, заполните это поле"),
  signatory_name: yup.string().required("Пожалуйста, заполните это поле"),
  signatory_official_number: yup
    .string()
    .matches(/^[0-9]{12,12}$/, "Минимальная длина - 12 символов")
    .required("Пожалуйста, заполните это поле"),
  official_number: yup
    .string()
    .matches(/^[0-9]{12,12}$/, "Минимальная длина - 12 символов")
    .required("Пожалуйста, заполните это поле"),
  official_address: yup.string().required("Пожалуйста, заполните это поле"),
  actual_address: yup.string().required("Пожалуйста, заполните это поле"),
  phone_number: yup.string().required("Пожалуйста, заполните это поле"),
});
export const validationSchemaStep2 = yup.object().shape({
  _checkboxGroup: yup
    .string()
    .test(
      "_checkboxGroup",
      "Пожалуйста, выберите хотя бы один уровень",
      function (value) {
        const {
          elementary_education,
          basic_secondary_education,
          basic_general_education,
          integrated_education,
        } = this.parent;

        return (
          elementary_education === "1" ||
          basic_secondary_education === "1" ||
          basic_general_education === "1" ||
          integrated_education === "1"
        );
      },
    ),
  tuition_fee: yup.string().required("Пожалуйста, заполните это поле"),
  // region: yup.string().required("Пожалуйста, заполните это поле"),
  // city: yup.string().required("Пожалуйста, заполните это поле"),
  // street: yup.string().required("Пожалуйста, заполните это поле"),
  // region_id: yup.string().required("Выберите регион"),
  // area_id: yup.string().required("Выберите район"),
  // locality_id: yup.string().required("Выберите населенный пункт"),
  // boarding_school_region_id: yup.string().required("Выберите регион"),
  // boarding_school_area_id: yup.string().required("Выберите район"),
  // boarding_school_district_id: yup.string().required("Выберите округ"),
  // boarding_school_locality_id: yup
  //   .string()
  //   .required("Выберите населенный пункт"),
  // area_and_street: yup.string().required("Пожалуйста, заполните это поле"),
  house_number: yup.string().required("Пожалуйста, заполните это поле"),
  school_name: yup.string().required("Пожалуйста, заполните это поле"),
  place_count: yup
    .string()
    .matches(/^[0-9]*$/, "Вы неправильно заполнили эта поля")
    .required("Пожалуйста, заполните это поле"),
  exploitation_year: yup.string().required("Пожалуйста, заполните это поле"),
  elementary_education: yup.string().required("Пожалуйста, заполните это поле"),
  basic_secondary_education: yup
    .string()
    .required("Пожалуйста, заполните это поле"),
  basic_general_education: yup
    .string()
    .required("Пожалуйста, заполните это поле"),
  integrated_education: yup.string().required("Пожалуйста, заполните это поле"),
  national_educational_db_registration: yup
    .string()
    .required("Пожалуйста, заполните это поле"),
  state_procurements_registration: yup
    .string()
    .required("Пожалуйста, заполните это поле"),
  boarding_school: yup.string().required("Пожалуйста, заполните это поле"),
  // boarding_school_region: yup.mixed().when("boarding_school", {
  //   is: "Есть",
  //   then: () =>
  //     yup.string().nullable().required("Пожалуйста, заполните это поле"),
  //   otherwise: () => yup.string().nullable().notRequired(),
  // }),
  // boarding_school_city: yup.string().when("boarding_school", {
  //   is: "Есть",
  //   then: () => yup.string().required("Пожалуйста, заполните это поле"),
  //   otherwise: () => yup.string().nullable().notRequired(),
  // }),
  // boarding_school_region_id: yup.mixed().when("boarding_school", {
  //   is: "Есть",
  //   then: () =>
  //     yup.string().nullable().required("Пожалуйста, заполните это поле"),
  //   otherwise: () => yup.string().nullable().notRequired(),
  // }),
  // boarding_school_area_id: yup.string().when("boarding_school", {
  //   is: "Есть",
  //   then: () => yup.string().required("Пожалуйста, заполните это поле"),
  //   otherwise: () => yup.string().nullable().notRequired(),
  // }),
  // boarding_school_district_id: yup.string().when("boarding_school", {
  //   is: "Есть",
  //   then: () => yup.string().required("Пожалуйста, заполните это поле"),
  //   otherwise: () => yup.string().nullable().notRequired(),
  // }),
  // boarding_school_locality_id: yup.string().when("boarding_school", {
  //   is: "Есть",
  //   then: () => yup.string().required("Пожалуйста, заполните это поле"),
  //   otherwise: () => yup.string().nullable().notRequired(),
  // }),
  boarding_school_street: yup.string().when("boarding_school", {
    is: "Есть",
    then: () => yup.string().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  boarding_school_house_number: yup.string().when("boarding_school", {
    is: "Есть",
    then: () => yup.string().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.string().nullable().notRequired(),
  }),

  boarding_school_exploitation_year: yup.string().when("boarding_school", {
    is: "Есть",
    then: () => yup.string().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  boarding_school_place_count: yup.string().when("boarding_school", {
    is: "Есть",
    then: () => yup.string().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  student_contingent: yup.string().required("Пожалуйста, заполните это поле"),
  private_organization_exploitation_type: yup
    .string()
    .required("Пожалуйста, заполните это поле"),
  // private_organization_government_order: yup
  //   .string()
  //   .required("Пожалуйста, заполните это поле"),
  license_number: yup.string().required("Пожалуйста, заполните это поле"),
  private_organization_place_count: yup
    .string()
    .when("exploitation_year", (exploitation_year: any, schema) => {
      //@ts-ignore
      if (
        exploitation_year &&
        new Date(BREAKPOINT_DATES.school) < new Date(exploitation_year)
      ) {
        return schema
          .matches(/^[0-9]*$/, "Вы неправильно заполнили это поле")
          .required("Пожалуйста, заполните это поле");
      }
      return schema.nullable();
    }),
  nobd_id: yup.mixed().when("national_educational_db_registration", {
    is: "Есть",
    then: () => yup.number().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.number().nullable().notRequired(),
  }),
  // private_organization_exploitation_year: yup
  //   .string()
  //   .when("exploitation_year", (exploitation_year, schema) => {
  //     //@ts-ignore
  //     if (exploitation_year && new Date("2018-07-20") < new Date(exploitation_year)) {
  //       return schema
  //         .matches(/^[0-9]{4}$/, "Не корректно заполнено")
  //         .required("Пожалуйста, заполните это поле");
  //     }
  //     return schema
  //       .nullable()
  //       // .matches(/^[0-9]{4}$/, "Не корректно заполнено")
  //       // .required("Пожалуйста, заполните это поле");

  //   }),
});
export const validationSchemaStep3 = yup.object().shape({
  private_organization_government_order: yup.number(),
  legal_entity_certificate: yup.mixed().required("Пожалуйста, загрузите файл"),
  tuition_fee_statement: yup.mixed().required("Пожалуйста, загрузите файл"),
  education_licence: yup.mixed().when("private_organization_government_order", {
    is: 0,
    then: () => yup.string().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  bank_certificate: yup.mixed().when("private_organization_government_order", {
    is: 0,
    then: () => yup.string().required("Пожалуйста, заполните это поле"),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  anti_terrorism_measures: yup
    .mixed()
    .when("private_organization_government_order", {
      is: 0,
      then: () => yup.string().required("Пожалуйста, заполните это поле"),
      otherwise: () => yup.string().nullable().notRequired(),
    }),
  // integrated_education_realization_documents: yup
  //   .mixed()
  //   .when("integrated_education", {
  //     is: 1,
  //     then: () => yup.string().required("Пожалуйста, заполните это поле"),
  //     otherwise: () => yup.string().nullable().notRequired(),
  //   }),
  preliminary_agreement: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_preliminary_agreement: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  immutability_agreement: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_immutability_agreement: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  project_documentation: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_project_documentation: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  exploitation_act: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_exploitation_act: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  real_estate_certificate: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_real_estate_certificate: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  cadastral_information: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_cadastral_information: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
      ],
      {
        is: (regImmutability: number, govOrder: number) =>
          regImmutability === 1 && govOrder === 0,
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  condition_conclusion: yup
    .mixed()
    .when(
      [
        "registered_immutability_agreement",
        "private_organization_government_order",
        "private_organization_exploitation_type",
      ],
      {
        is: (regImmutability: number, govOrder: number, expType: string) =>
          regImmutability === 1 &&
          govOrder === 0 &&
          expType === "Реконструкция",
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
  boarding_school_condition_conclusion: yup
    .mixed()
    .when(
      [
        "boarding_school_registered_immutability_agreement",
        "private_organization_government_order",
        "boarding_school_exploitation_type",
      ],
      {
        is: (regImmutability: number, govOrder: number, expType: string) =>
          regImmutability === 1 &&
          govOrder === 0 &&
          expType === "Реконструкция",
        then: () => yup.string().required("Пожалуйста, заполните это поле"),
        otherwise: () => yup.string().nullable().notRequired(),
      },
    ),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  step1: yup.object({
    organization_name: yup.string().required("Пожалуйста, заполните это поле"),
    email_address: yup
      .string()
      .email("Пожалуйста, введите корректный e-mail")
      .required("Пожалуйста, заполните это поле"),
    signatory_name: yup.string().required("Пожалуйста, заполните это поле"),
    signatory_official_number: yup
      .string()
      .matches(/^[0-9]{12,12}$/, "Не корректный ИИН")
      .required("Пожалуйста, заполните это поле"),
    official_number: yup
      .string()
      .matches(/^[0-9]{12,12}$/, "Не корректный ИИН")
      .required("Пожалуйста, заполните это поле"),
    official_address: yup.string().required("Пожалуйста, заполните это поле"),
    actual_address: yup.string().required("Пожалуйста, заполните это поле"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid"),
  }),
  step2: yup.object({
    email: yup
      .string()
      .email("Пожалуйста, введите корректный e-mail")
      .required("Пожалуйста, заполните это поле"),
    password: yup.string().required("Пожалуйста, заполните это поле"),
    passwordTEST: yup.string().required("Пожалуйста, заполните это поле"),
  }),
  step3: yup.object({
    email: yup
      .string()
      .email("Пожалуйста, введите корректный e-mail")
      .required("Пожалуйста, заполните это поле"),
    password: yup.string().required("Пожалуйста, заполните это поле"),
    passwordTEST: yup.string().required("Пожалуйста, заполните это поле"),
  }),
});
