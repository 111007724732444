import { styled } from "@mui/material/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useCheckCellsValues } from "../../../../hooks/useCheckCellsValues";
import { useAppSelector } from "../../../../store/hooks";
import {
  selectBoardingPredictiveContingentDataTable,
  selectBoardingSchool,
  selectBoardingStudentsDataTable,
  selectPredictiveContingentDataTable,
  selectStudentsDataTable,
} from "../../../../store/reducers/createDeclaration";
import { SchoolTableRows } from "../../../../types/tableRowsTypes";

interface TableState {
  rows: SchoolTableRows[];
  setRows: (e: SchoolTableRows[]) => void;
  setRowsAll: (e: SchoolTableRows[]) => void;
  isInputDisabled: boolean;
  isClassesDisabled: boolean;
  isCurrent: boolean;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  width: "100%",
  "& .MuiDataGrid-cell": {
    position: "relative",
    lineHeight: "unset !important",
    maxHeight: "none !important",
    whiteSpace: "normal",
    padding: 0,
  },
  "& .MuiDataGrid-columnHeaders": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#F1F3F9",
    height: "40px",
    "& .MuiButtonBase-root": {
      display: "none",
    },
  },
  "& .MuiDataGrid-renderingZone": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-row": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

const CustomEditableCell: React.FC<any> = (params) => {
  const {
    isInputDisabled,
    isClassesDisabled,
    onCellValueChange,
    id,
    field,
    value,
    isCurrent,
  } = params;
  const boardingSchool = useAppSelector(selectBoardingSchool);

  const [localValue, setLocalValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const checkCellsValues = useCheckCellsValues(isCurrent).checkValues;
  const studentsDataTable = useAppSelector(
    isCurrent ? selectStudentsDataTable : selectPredictiveContingentDataTable,
  );
  const boardingStudentsDataTable = useAppSelector(
    isCurrent
      ? selectBoardingStudentsDataTable
      : selectBoardingPredictiveContingentDataTable,
  );

  const schoolRow = studentsDataTable?.find((row) => row.id === id);
  const boardingRow = boardingStudentsDataTable?.find((row) => row.id === id);

  useEffect(() => {
    if (
      params.field === "all_pupils" ||
      params.field === "classes" ||
      params.id === 4 ||
      params.id === 5 ||
      params.id === 6 ||
      params.id === 7
    ) {
      setLocalValue(value);
    }
    //setLocalValue(value);
  }, [value]);

  const handleCheckValues = () => {
    if (!isInputDisabled && studentsDataTable && boardingStudentsDataTable) {
      if (schoolRow && boardingRow) {
        const validationResults = checkCellsValues(
          schoolRow,
          boardingRow,
          id,
        ).validationResult;
        const fieldKey = field as keyof typeof validationResults;
        if (boardingSchool === "Есть") {
          setIsValid(validationResults[fieldKey]);
        }
      }
      // else {
      //   console.error("Строки для проверки не найдены");
      // }
    }
  };
  useEffect(() => {
    handleCheckValues();
  }, [studentsDataTable, boardingStudentsDataTable, isInputDisabled]);
  useEffect(() => {
    if (boardingSchool !== "Есть") {
      setIsValid(true);
    }
  }, [boardingSchool]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const newValue = event.target.value.replace(/[^\d.]/g, "");
    const newValue = event.target.value;
    setLocalValue(newValue);
    onCellValueChange(id, field, newValue);
  };
  const handleBlur = () => {
    if (localValue === "" || value === "0") {
      const newValue = 0;
      setLocalValue("");
      onCellValueChange(id, field, newValue);
    }
  };
  const handleFocus = () => {
    if (
      value === 0 &&
      field !== "all_pupils" &&
      field !== "classes" &&
      id !== 4
    ) {
      setLocalValue("");
    }
  };
  const isDisabled =
    params.field === "all_pupils" ||
    (params.field === "classes" && isClassesDisabled) ||
    params.id === 4 ||
    isInputDisabled;
  return (
    <input
      value={localValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0)",
        padding: "0 5px",
        border: isValid ? "none" : "1px solid red",
        //  border: "none",
      }}
      disabled={isDisabled}
    />
  );
};

const columns: GridColDef[] = [
  {
    field: "classes",
    headerName: "Классы",
    width: 140,
    editable: false,
  },
  {
    field: "all_pupils",
    headerName: "Всего учащихся (человек)",
    width: 140,
    editable: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "common_pupils",
    headerName: "Учащиеся общеобразовательных классов",
    width: 220,
    editable: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "special_pupils",
    headerName:
      "Лица (дети) с особыми образовательными потребностями, обучающиеся в общеобразовательных классах",
    width: 380,
    editable: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "correction_pupils",
    headerName: "Учащиеся специальных (коррекционных) классов",
    width: 220,
    editable: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "home_pupils",
    headerName: "Обучающиеся на дому",
    flex: 1,
    editable: false,
    renderCell: CustomEditableCell,
  },
];
const getRowHeight = (params: any) => {
  if (params.rowIndex === 1) {
    return 200;
  } else {
    return 40;
  }
};
const totalRowData = {
  id: 4,
  classSchool: "Итого",
  classSummStudents: 0,
  normativClassDaily: 0,
  normativClassBoarding: 0,
  specialClassDaily: 0,
  specialClassBoarding: 0,
  correctiveClassDaily: 0,
  correctiveClassBoarding: 0,
  homeSchoolersDaily: 0,
};
const TableComponent: React.FC<TableState> = ({
  rows,
  setRows,
  setRowsAll,
  isInputDisabled,
  isClassesDisabled,
  isCurrent,
}) => {
  const [totalRow, setTotalRow] = useState<any>(totalRowData);
  const handleCellValueChange = (id: number, field: string, newValue: any) => {
    const updatedRows = rows.map((row) => {
      // row.all_pupils =
      //   Number(row.common_pupils) +
      //   Number(row.home_pupils) +
      //   Number(row.correction_pupils) +
      //   Number(row.special_pupils);
      if (row.id === id) {
        return { ...row, [field]: newValue };
      }
      return row;
    });

    setRows(updatedRows);
  };
  const handleCellClick = (params: any) => {
    if (
      params.value === 0 &&
      params.field !== "all_pupils" &&
      params.field !== "classes" &&
      params.id !== 4
    ) {
      handleCellValueChange(params.id, params.field, "");

      // Обновляем значение в rows напрямую, без использования setRows
      const updatedRows = rows.map((row) => {
        if (row.id === params.id) {
          return { ...row, [params.field]: "" };
        }
        return row;
      });
      setRows(updatedRows);
    }
  };

  const calculateTotalSumm = () => {
    let isChanged = false;
    const updatedRows = rows.map((row) => {
      const allPupils =
        Number(row.common_pupils) +
        Number(row.special_pupils) +
        Number(row.correction_pupils) +
        Number(row.home_pupils);

      if (allPupils !== row.all_pupils) {
        isChanged = true;
        return { ...row, all_pupils: allPupils };
      }
      return row;
    });

    if (isChanged) {
      setRows(updatedRows);
    }
  };
  const calculateTotal = () => {
    const total = {
      id: 4,
      classes: "Итого",
      all_pupils: 0,
      common_pupils: 0,
      special_pupils: 0,
      correction_pupils: 0,
      home_pupils: 0,
    };

    rows.forEach((row) => {
      total.all_pupils += Number(row.all_pupils);
      total.common_pupils += Number(row.common_pupils);
      total.special_pupils += Number(row.special_pupils);
      total.correction_pupils += Number(row.correction_pupils);
      total.home_pupils += Number(row.home_pupils);
    });

    setTotalRow(total);
  };

  useEffect(() => {
    calculateTotalSumm();
    calculateTotal();
  }, [rows]);
  useEffect(() => {
    setRowsAll(rows.concat(totalRow));
  }, [totalRow, rows]);
  return (
    <>
      <StyledDataGrid
        getRowHeight={getRowHeight}
        disableColumnMenu={true}
        hideFooterPagination={true}
        // getRowHeight={() => "auto"}
        hideFooter={true}
        editMode={"row"}
        disableColumnSelector={true}
        sortModel={[]}
        columnHeaderHeight={55}
        rows={rows.concat(totalRow)}
        columns={columns.map((column) => ({
          ...column,
          renderCell: (params) => (
            <CustomEditableCell
              {...params}
              isInputDisabled={isInputDisabled}
              isClassesDisabled={isClassesDisabled}
              onCellValueChange={handleCellValueChange}
              isCurrent={isCurrent}
            />
          ),
        }))}
        onCellClick={handleCellClick}
      />
    </>
  );
};

export default TableComponent;
